<template>
  <header id="header" class="fixed-top header-scrolled header-inner-pages">
    <div class="container d-flex align-items-center">
      <h1 class="logo me-auto">
        <a href="index.html"
          ><img
            src="atl-logo.png"
            class="img-fluid animated"
            width="125"
            alt=""
        /></a>
      </h1>
      <!-- Uncomment below if you prefer to use an image logo -->
      <!-- <a href="index.html" class="logo me-auto"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

      <nav id="navbar" class="navbar">
        <ul>
          <li>
            <a
              :class="`nav-link scrollto ${menu[0].beranda.value}`"
              href="#hero"
              @click="set_active('beranda')"
              >Beranda</a
            >
          </li>
          <li>
            <a
              class="nav-link scrollto"
              :class="menu[0].tentang_kami.value"
              href="#about"
              @click="set_active('tentang_kami')"
              >Tentang Kami</a
            >
          </li>
          <li>
            <a
              :class="`nav-link scrollto ${menu[0].layanan.value}`"
              href="#services"
              @click="set_active('layanan')"
              >Layanan</a
            >
          </li>
          <li>
            <a
              :class="`nav-link scrollto ${menu[0].portofolio.value}`"
              href="#portfolio"
              @click="set_active('portofolio')"
              >Portofolio</a
            >
          </li>
          <li>
            <a
              :class="`nav-link scrollto ${menu[0].cek_tarif.value}`"
              href="#pricing"
              @click="set_active('cek_tarif')"
              >Cek Tarif</a
            >
          </li>
          <!-- <li><a class="nav-link scrollto" href="#team">Team</a></li> -->
          <!-- <li class="dropdown">
            <a href="#"
              ><span>Drop Down</span> <i class="bi bi-chevron-down"></i
            ></a>
            <ul>
              <li><a href="#">Drop Down 1</a></li>
              <li class="dropdown">
                <a href="#"
                  ><span>Deep Drop Down</span>
                  <i class="bi bi-chevron-right"></i
                ></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
              <li><a href="#">Drop Down 2</a></li>
              <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li>
            </ul>
          </li> -->
          <li>
            <a
              :class="`nav-link scrollto ${menu[0].kontak.value}`"
              href="#contact"
              @click="set_active('kontak')"
              >Kontak</a
            >
          </li>
          <!-- <li><a class="getstarted scrollto" href="#about">Get Started</a></li> -->
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
      <!-- .navbar -->
    </div>
  </header>
  <!-- End Header -->
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    // ====== set variable ======
    var menu = [
      {
        beranda: ref("active"),
        tentang_kami: ref(""),
        layanan: ref(""),
        portofolio: ref(""),
        cek_tarif: ref(""),
        kontak: ref(""),
      },
    ];

    // ====== set function ======

    function set_active(name) {
      menu.map((e) => {
        e.beranda.value = name === "beranda" ? "active" : "";
        e.tentang_kami.value = name === "tentang_kami" ? "active" : "";
        e.layanan.value = name === "layanan" ? "active" : "";
        e.portofolio.value = name === "portofolio" ? "active" : "";
        e.cek_tarif.value = name === "cek_tarif" ? "active" : "";
        e.kontak.value = name === "kontak" ? "active" : "";
      });
    }

    // expose the ref to the template
    return {
      // ====== call variable ======
      menu,

      // ====== call function ======
      set_active,
    };
  },
};
</script>
