<template>
  <!-- ======= Services Section ======= -->
  <section id="services" class="services section-bg">
    <div class="container" data-aos="fade-up">
      <div class="section-title" style="margin-top: 50px">
        <h2>Layanan</h2>
        <p>
          Layanan kami dalam bidang jasa ekspedisi laut menawarkan solusi
          komprehensif untuk mengatasi kebutuhan pengiriman barang melalui air.
          Kami mengutamakan keandalan dan efisiensi, memastikan barang pelanggan
          kami tiba tepat waktu dan dalam kondisi baik.
        </p>
      </div>

      <div class="row">
        <div
          class="col-xl-4 col-md-6 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bxs-ship"></i></div>
            <h4><a href="">Pengiriman melalui angkutan laut</a></h4>
            <p>
              Pengiriman melalui angkutan laut adalah metode yang andal dan
              hemat biaya untuk mengangkut barang jarak jauh
            </p>
          </div>
        </div>

        <div
          class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-box"></i></div>
            <h4><a href="">Pengiriman barang</a></h4>
            <p>
              Pengiriman yang efisien memastikan bahwa produk sampai ke penerima
              yang dituju secara tepat waktu dan utuh
            </p>
          </div>
        </div>

        <div
          class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
          data-aos="zoom-in"
          data-aos-delay="300"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-car"></i></div>
            <h4><a href="">Pengiriman kendaraan</a></h4>
            <p>
              Pengiriman kendaraan adalah suatu cara pengiriman kendaraan,
              seperti mobil atau sepeda motor, melalui kapal laut ke tujuan yang
              jauh.
            </p>
          </div>
        </div>

        <!-- <div
          class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
          data-aos="zoom-in"
          data-aos-delay="400"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-layer"></i></div>
            <h4><a href="">Nemo Enim</a></h4>
            <p>
              At vero eos et accusamus et iusto odio dignissimos ducimus qui
              blanditiis
            </p>
          </div>
        </div> -->
      </div>
    </div>
  </section>
  <!-- End Services Section -->
</template>
