<template>
  <section id="hero" class="d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <h1>Pilihan terbaik untuk pengiriman barang dan kendaraan anda</h1>
          <!-- <h2>
            We are team of talented designers making websites with Bootstrap
          </h2> -->
          <div class="d-flex justify-content-center justify-content-lg-start">
            <a href="#about" class="btn-get-started scrollto">Mulai sekarang</a>
            <!-- <a
              href="https://www.youtube.com/watch?v=jDDaplaOz7Q"
              class="glightbox btn-watch-video"
              ><i class="bi bi-play-circle"></i><span>Watch Video</span></a
            > -->
          </div>
        </div>
        <div
          class="col-lg-6 order-1 order-lg-2 hero-img"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <img
            src="bg-kapal.jpeg"
            class="img-fluid animated"
            width="530"
            alt=""
            style="border-radius: 20px 20px 10px"
          />
        </div>
      </div>
    </div>
  </section>
</template>
