<template>
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">
      <div class="section-title" style="margin-top: 50px">
        <h2>Kontak kami</h2>
        <p>
          Untuk semua pertanyaan, informasi lebih lanjut, atau kolaborasi
          bisnis, kami dengan senang hati siap melayani Anda. Silakan hubungi
          kami melalui alamat berikut:
        </p>
      </div>

      <div class="row">
        <div class="col-lg-5 d-flex align-items-stretch">
          <div class="info">
            <div class="address">
              <i class="bi bi-geo-alt"></i>
              <h4>Lokasi:</h4>
              <p>
                Jl. Lagoa trs Gg 1 B1 no. 38 rt rw 014 002 kel lagoa kec koja
                jakarta utara 14270
              </p>
            </div>

            <div class="email">
              <i class="bi bi-envelope"></i>
              <h4>Email:</h4>
              <p>amantransindologistik@gmail.com</p>
            </div>

            <div class="phone">
              <i class="bi bi-phone"></i>
              <h4>Telp:</h4>
              <p>082124616727</p>
            </div>
          </div>
        </div>

        <div class="col-lg-7 d-flex align-items-stretch">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
            frameborder="0"
            style="border: 0; width: 100%; height: 315px"
            allowfullscreen
          ></iframe>
        </div>

        <!-- <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
          <form
            action="forms/contact.php"
            method="post"
            role="form"
            class="php-email-form"
          >
            <div class="row">
              <div class="form-group col-md-6">
                <label for="name">Your Name</label>
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  id="name"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="name">Your Email</label>
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                  required
                />
              </div>
            </div>
            <div class="form-group">
              <label for="name">Subject</label>
              <input
                type="text"
                class="form-control"
                name="subject"
                id="subject"
                required
              />
            </div>
            <div class="form-group">
              <label for="name">Message</label>
              <textarea
                class="form-control"
                name="message"
                rows="10"
                required
              ></textarea>
            </div>
            <div class="my-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">
                Your message has been sent. Thank you!
              </div>
            </div>
            <div class="text-center">
              <button type="submit">Send Message</button>
            </div>
          </form>
        </div> -->
      </div>
    </div>
  </section>
  <!-- End Contact Section -->
</template>
