<template>
  <div>
    <!-- <a href="/">Home</a>
      <a href="/about">About</a> -->
    <!-- <router-link :to="{ name: 'Home' }">Home</router-link> |
      <router-link :to="{ name: 'About' }">About</router-link> -->
    <!-- <router-view></router-view> -->

    <!-- HEADER -->
    <Header />
    <!-- Hero Section -->
    <Hero />
    <main id="main">
      <!-- ======= Clients Section ======= -->
      <Client />
      <!-- End Cliens Section -->
      <!-- ======= About Us Section ======= -->
      <AboutUs />
      <!-- End About Us Section -->
      <!-- ======= Why Us Section ======= -->
      <!-- <WhyUs /> -->
      <!-- End Why Us Section -->
      <!-- ======= Skills Section ======= -->
      <!-- <Skills /> -->
      <!-- End Skills Section -->
      <!-- ======= Services Section ======= -->
      <Services />
      <!-- End Services Section -->
      <!-- ======= Cta Section ======= -->
      <!-- <Cta /> -->
      <!-- End Cta Section -->
      <!-- ======= Portfolio Section ======= -->
      <Portfolio />
      <!-- End Portfolio Section -->
      <!-- ======= Team Section ======= -->
      <!-- <Team /> -->
      <!-- End Team Section -->
      <!-- ======= Pricing Section ======= -->
      <Pricing />
      <!-- End Pricing Section -->
      <!-- ======= Faq Section ======= -->
      <!-- <Faq /> -->
      <!-- End Faq Section -->
      <!-- ======= Contact Section ======= -->
      <Contact />
      <!-- End Contact Section -->
    </main>
    <!-- FOOTER -->
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Hero from "./components/Hero.vue";
import Client from "./components/Client.vue";
import AboutUs from "./components/AboutUs.vue";
// import WhyUs from "./components/WhyUs.vue";
// import Skills from "./components/Skills.vue";
import Services from "./components/Services.vue";
// import Cta from "./components/Cta.vue";
import Portfolio from "./components/Portfolio.vue";
// import Team from "./components/Team.vue";
import Pricing from "./components/Pricing.vue";
// import Faq from "./components/Faq.vue";
import Contact from "./components/Contact.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Hero,
    Client,
    AboutUs,
    // WhyUs,
    // Skills,
    Services,
    // Cta,
    Portfolio,
    // Team,
    Pricing,
    // Faq,
    Contact,
    Footer,
  },
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
