<template>
  <section id="about" class="about">
    <div class="container" data-aos="fade-up">
      <div class="section-title" style="margin-top: 50px">
        <h2>Tentang Kami</h2>
      </div>

      <div class="row content">
        <div class="col-lg-6">
          <p>
            Kami adalah <b>perusahaan ekspedisi angkutan laut</b> yang
            berdedikasi untuk menyediakan solusi logistik maritim yang
            <b>efisien dan hemat biaya</b> bagi klien kami, memastikan
            pengiriman kargo yang andal dan tepat waktu melintasi lautan.
          </p>
          <!-- <p><b>Vision</b></p>
          <p>
            "To be a leader in the maritime shipping services industry, with a
            commitment to connecting the world by sea, providing sustainable
            logistics solutions, and providing the best service to our
            customers."
          </p> -->
          <ul>
            <li>
              <i class="ri-check-double-line"></i> Menyediakan jasa ekspedisi
              laut yang efisien dan terpercaya, menjamin pengiriman tepat waktu
              dan biaya yang kompetitif.
            </li>
            <li>
              <i class="ri-check-double-line"></i>Berinvestasi pada teknologi
              canggih untuk meningkatkan transparansi dan efisiensi dalam
              operasi kami.
            </li>
            <li>
              <i class="ri-check-double-line"></i> Mengembangkan dan memelihara
              hubungan jangka panjang dengan pelanggan, mitra, dan pemangku
              kepentingan lainnya.
            </li>
          </ul>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p>
            Kami selalu berkomitmen untuk memberikan kepuasan kepada semua klien
            kami sesuai dengan moto kami
            <b>"Kepuasan klien adalah energi kami"</b>.
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i> Terus berinovasi dalam
              memberikan solusi logistik yang sesuai dengan perkembangan pasar
              dan kebutuhan pelanggan.
            </li>
            <li>
              <i class="ri-check-double-line"></i> Memberdayakan karyawan kami
              dengan pelatihan dan pengembangan berkelanjutan untuk mencapai
              tingkat keunggulan layanan yang tinggi.
            </li>
            <li>
              <i class="ri-check-double-line"></i> Menjunjung tinggi etika
              bisnis, integritas, dan komitmen terhadap tanggung jawab sosial
              perusahaan.
            </li>
          </ul>
          <!-- <a href="#" class="btn-learn-more">Learn More</a> -->
        </div>
      </div>
    </div>
  </section>
  <!-- End About Us Section -->
</template>
