<template>
  <!-- ======= Pricing Section ======= -->
  <section id="pricing" class="pricing">
    <div class="container" data-aos="fade-up">
      <div class="section-title" style="margin-top: 50px">
        <h2>Cek Tarif</h2>
        <!-- <p>
          Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex
          aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos
          quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
          fugiat sit in iste officiis commodi quidem hic quas.
        </p> -->
      </div>

      <div class="section-title">
        <h5>Tarif Pengiriman Barang</h5>
      </div>

      <div class="row mb-5" style="margin-top: -50px">
        <div class="col-lg-12 col-md-12">
          <DataTable
            :columns="columnsHargaBarang"
            :options="options"
            ajax="/data/harga_barang.json"
            class="display nowrap"
            width="100%"
          >
            <thead>
              <tr>
                <th>Asal</th>
                <th>Tujuan</th>
                <th>Harga/Kg</th>
                <th>Minimal Berat</th>
                <th>Estimasi</th>
                <th>Keterangan</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th>Asal</th>
                <th>Tujuan</th>
                <th>Harga/Kg</th>
                <th>Minimal Berat</th>
                <th>Estimasi</th>
                <th>Keterangan</th>
              </tr>
            </tfoot>
          </DataTable>
        </div>
      </div>

      <hr class="mb-5" />

      <div class="section-title">
        <h5>Tarif Pengiriman Kendaraan</h5>
      </div>

      <div class="row mb-5" style="margin-top: -50px">
        <div class="col-lg-12 col-md-12">
          <DataTable
            :columns="columnsHargaKendaraan"
            :options="options"
            ajax="/data/harga_kendaraan.json"
            class="display nowrap"
            width="100%"
          >
            <thead>
              <tr>
                <th>Asal</th>
                <th>Tujuan</th>
                <th>Harga</th>
                <th>Estimasi</th>
                <th>Keterangan</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th>Asal</th>
                <th>Tujuan</th>
                <th>Harga</th>
                <th>Estimasi</th>
                <th>Keterangan</th>
              </tr>
            </tfoot>
          </DataTable>
        </div>
      </div>

      <hr />

      <!-- <div class="row">
        <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
          <div class="box">
            <h3>Free Plan</h3>
            <h4><sup>$</sup>0<span>per month</span></h4>
            <ul>
              <li><i class="bx bx-check"></i> Quam adipiscing vitae proin</li>
              <li><i class="bx bx-check"></i> Nec feugiat nisl pretium</li>
              <li><i class="bx bx-check"></i> Nulla at volutpat diam uteera</li>
              <li class="na">
                <i class="bx bx-x"></i>
                <span>Pharetra massa massa ultricies</span>
              </li>
              <li class="na">
                <i class="bx bx-x"></i>
                <span>Massa ultricies mi quis hendrerit</span>
              </li>
            </ul>
            <a href="#" class="buy-btn">Get Started</a>
          </div>
        </div>

        <div
          class="col-lg-4 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div class="box featured">
            <h3>Business Plan</h3>
            <h4><sup>$</sup>29<span>per month</span></h4>
            <ul>
              <li><i class="bx bx-check"></i> Quam adipiscing vitae proin</li>
              <li><i class="bx bx-check"></i> Nec feugiat nisl pretium</li>
              <li><i class="bx bx-check"></i> Nulla at volutpat diam uteera</li>
              <li>
                <i class="bx bx-check"></i> Pharetra massa massa ultricies
              </li>
              <li>
                <i class="bx bx-check"></i> Massa ultricies mi quis hendrerit
              </li>
            </ul>
            <a href="#" class="buy-btn">Get Started</a>
          </div>
        </div>

        <div
          class="col-lg-4 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div class="box">
            <h3>Developer Plan</h3>
            <h4><sup>$</sup>49<span>per month</span></h4>
            <ul>
              <li><i class="bx bx-check"></i> Quam adipiscing vitae proin</li>
              <li><i class="bx bx-check"></i> Nec feugiat nisl pretium</li>
              <li><i class="bx bx-check"></i> Nulla at volutpat diam uteera</li>
              <li>
                <i class="bx bx-check"></i> Pharetra massa massa ultricies
              </li>
              <li>
                <i class="bx bx-check"></i> Massa ultricies mi quis hendrerit
              </li>
            </ul>
            <a href="#" class="buy-btn">Get Started</a>
          </div>
        </div>
      </div> -->
    </div>
  </section>
  <!-- End Pricing Section -->
</template>

<style>
@import "datatables.net-dt";
</style>

<script setup>
import { ref } from "vue";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
DataTable.use(DataTablesCore);

const columnsHargaBarang = ref([
  { data: "asal" },
  { data: "tujuan" },
  { data: "harga" },
  { data: "minimal_berat" },
  { data: "estimasi" },
  { data: "keterangan" },
]);

const columnsHargaKendaraan = ref([
  { data: "asal" },
  { data: "tujuan" },
  { data: "harga" },
  { data: "estimasi" },
  { data: "keterangan" },
]);

const options = {
  responsive: true,
  select: true,
};
</script>
