<template>
  <!-- ======= Portfolio Section ======= -->
  <section id="portfolio" class="portfolio">
    <div class="container" data-aos="fade-up">
      <div class="section-title" style="margin-top: 50px">
        <h2>Portofolio</h2>
        <p>
          Beberapa portofolio layanan pengiriman kami terlihat dari banyaknya
          proyek pengiriman yang berhasil kami selesaikan.
        </p>
      </div>

      <ul
        id="portfolio-flters"
        class="d-flex justify-content-center"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <!-- <li data-filter="*" class="filter-active">All</li> -->
        <!-- <li data-filter=".filter-app">App</li>
        <li data-filter=".filter-card">Card</li>
        <li data-filter=".filter-web">Web</li> -->
      </ul>

      <div
        class="row portfolio-container"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-img">
            <img
              src="assets/img/portfolio//atl1.jpeg"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="portfolio-info">
            <h4>Portfolio 1</h4>
            <a
              href="assets/img/portfolio/atl1.jpeg"
              data-gallery="portfolioGallery"
              class="portfolio-lightbox preview-link"
              title="App 1"
              ><i class="bx bx-plus"></i
            ></a>
            <!-- <a
              href="portfolio-details.html"
              class="details-link"
              title="More Details"
              ><i class="bx bx-link"></i
            ></a> -->
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-img">
            <img
              src="assets/img/portfolio/atl2.jpeg"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="portfolio-info">
            <h4>Portfolio 2</h4>
            <!-- <p>Web</p> -->
            <a
              href="assets/img/portfolio/atl2.jpeg"
              data-gallery="portfolioGallery"
              class="portfolio-lightbox preview-link"
              title="Web 3"
              ><i class="bx bx-plus"></i
            ></a>
            <!-- <a
              href="portfolio-details.html"
              class="details-link"
              title="More Details"
              ><i class="bx bx-link"></i
            ></a> -->
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-img">
            <img
              src="assets/img/portfolio/atl3.jpeg"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="portfolio-info">
            <h4>Portfolio 3</h4>
            <!-- <p>App</p> -->
            <a
              href="assets/img/portfolio/atl3.jpeg"
              data-gallery="portfolioGallery"
              class="portfolio-lightbox preview-link"
              title="App 2"
              ><i class="bx bx-plus"></i
            ></a>
            <!-- <a
              href="portfolio-details.html"
              class="details-link"
              title="More Details"
              ><i class="bx bx-link"></i
            ></a> -->
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card">
          <div class="portfolio-img">
            <img
              src="assets/img/portfolio/atl4.jpeg"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="portfolio-info">
            <h4>Portfolio 4</h4>
            <!-- <p>Card</p> -->
            <a
              href="assets/img/portfolio/atl4.jpeg"
              data-gallery="portfolioGallery"
              class="portfolio-lightbox preview-link"
              title="Card 2"
              ><i class="bx bx-plus"></i
            ></a>
            <!-- <a
              href="portfolio-details.html"
              class="details-link"
              title="More Details"
              ><i class="bx bx-link"></i
            ></a> -->
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-img">
            <img
              src="assets/img/portfolio/atl5.jpeg"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="portfolio-info">
            <h4>Portfolio 5</h4>
            <!-- <p>Web</p> -->
            <a
              href="assets/img/portfolio/atl5.jpeg"
              data-gallery="portfolioGallery"
              class="portfolio-lightbox preview-link"
              title="Web 2"
              ><i class="bx bx-plus"></i
            ></a>
            <!-- <a
              href="portfolio-details.html"
              class="details-link"
              title="More Details"
              ><i class="bx bx-link"></i
            ></a> -->
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-img">
            <img
              src="assets/img/portfolio/atl6.jpeg"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="portfolio-info">
            <h4>Portfolio 6</h4>
            <!-- <p>App</p> -->
            <a
              href="assets/img/portfolio/atl6.jpeg"
              data-gallery="portfolioGallery"
              class="portfolio-lightbox preview-link"
              title="App 3"
              ><i class="bx bx-plus"></i
            ></a>
            <!-- <a
              href="portfolio-details.html"
              class="details-link"
              title="More Details"
              ><i class="bx bx-link"></i
            ></a> -->
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card">
          <div class="portfolio-img">
            <img
              src="assets/img/portfolio/alt8.jpeg"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="portfolio-info">
            <h4>Portfolio 7</h4>
            <!-- <p>Card</p> -->
            <a
              href="assets/img/portfolio/alt8.jpeg"
              data-gallery="portfolioGallery"
              class="portfolio-lightbox preview-link"
              title="Card 1"
              ><i class="bx bx-plus"></i
            ></a>
            <!-- <a
              href="portfolio-details.html"
              class="details-link"
              title="More Details"
              ><i class="bx bx-link"></i
            ></a> -->
          </div>
        </div>

        <!--  <div class="col-lg-4 col-md-6 portfolio-item filter-card">
          <div class="portfolio-img">
            <img
              src="assets/img/portfolio/portfolio-8.jpg"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="portfolio-info">
            <h4>Card 3</h4>
            <p>Card</p>
            <a
              href="assets/img/portfolio/portfolio-8.jpg"
              data-gallery="portfolioGallery"
              class="portfolio-lightbox preview-link"
              title="Card 3"
              ><i class="bx bx-plus"></i
            ></a>
            <a
              href="portfolio-details.html"
              class="details-link"
              title="More Details"
              ><i class="bx bx-link"></i
            ></a>
          </div>
        </div> -->

        <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-img">
            <img
              src="assets/img/portfolio/portfolio-9.jpg"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="portfolio-info">
            <h4>Web 3</h4>
            <p>Web</p>
            <a
              href="assets/img/portfolio/portfolio-9.jpg"
              data-gallery="portfolioGallery"
              class="portfolio-lightbox preview-link"
              title="Web 3"
              ><i class="bx bx-plus"></i
            ></a>
            <a
              href="portfolio-details.html"
              class="details-link"
              title="More Details"
              ><i class="bx bx-link"></i
            ></a>
          </div>
        </div> -->
      </div>
    </div>
  </section>
  <!-- End Portfolio Section -->
</template>
